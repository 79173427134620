export const GOOGLE_MAPS_API_KEY = 'AIzaSyC7-znqV77tWrYCQPT_z7XrMB-mdJPtRgQ'

export enum Method {
  MWL = 'MWL',
  ISNA = 'ISNA',
  EGYPT = 'Egypt',
  MAKKAH = 'Makkah',
  KARACHI = 'Karachi',
  TEHRAN = 'Tehran',
  JAFARI = 'Jafari'
}

export const methods: Array<{ id: Method, name: string }> = [
  {
    id: Method.MWL,
    name: 'Muslim World League'
  },
  {
    id: Method.ISNA,
    name: 'Islamic Society of North America (ISNA)'
  },
  {
    id: Method.EGYPT,
    name: 'Egyptian General Authority of Survey'
  },
  {
    id: Method.MAKKAH,
    name: 'Umm Al-Qura University, Makkah'
  },
  {
    id: Method.KARACHI,
    name: 'University of Islamic Sciences, Karachi'
  },
  {
    id: Method.TEHRAN,
    name: 'Institute of Geophysics, University of Tehran'
  },
  {
    id: Method.JAFARI,
    name: 'Shia Ithna-Ashari, Leva Institute, Qum'
  }
]

export const months = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
]
