import React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import parse from 'autosuggest-highlight/parse'
import { debounce } from '@mui/material/utils'

import { GOOGLE_MAPS_API_KEY } from 'utils/constants'
import { loadScript } from 'utils/loadScript'

const autocompleteService = { current: null }
const detailsService = { current: null }

interface MainTextMatchedSubstrings {
  offset: number
  length: number
}
interface StructuredFormatting {
  main_text: string
  secondary_text: string
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[]
}
export interface PlaceType {
  description: string
  structured_formatting: StructuredFormatting
  place_id: string
}
export interface PlaceDetails {
  geometry: {
    'location': {
      'lat': number
      'lng': number
    }
  }
  utc_offset_minutes: number
}
export interface Place { place: PlaceType, details: PlaceDetails | undefined }

interface GoogleMapsProps {
  onSelect: (value: Place) => void
  defaultValue?: PlaceType | null
}

const GoogleMaps: React.FC<GoogleMapsProps> = ({ onSelect, defaultValue }) => {
  const [value, setValue] = React.useState<PlaceType | null | undefined>(defaultValue)
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<readonly PlaceType[]>([])
  const loaded = React.useRef(false)

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      )
    }

    loaded.current = true
  }

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback
          )
        },
        400
      ),
    []
  )

  const fetchDetails = React.useMemo(() => debounce(
    (
      request: {
        placeId: string
        fields: string[]
      },
      callback: (results?: PlaceDetails) => void
    ) => {
      try {
        (detailsService.current as any).getDetails(
          request,
          callback
        )
      } catch {
        callback()
      }
    },
    400
  ), [])

  React.useEffect(() => {
    if (value) {
      fetchDetails({ placeId: value.place_id, fields: ['geometry', 'utc_offset_minutes'] }, (results) => {
        onSelect({ place: value, details: results })
      })
    }
  }, [value, onSelect, fetchDetails])

  React.useEffect(() => {
    let active = true

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService()
    }

    if (!detailsService.current && (window as any).google) {
      detailsService.current = new (
        window as any
      ).google.maps.places.PlacesService(document.querySelector('#google-maps'))
    }

    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = []

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  return (
    <Autocomplete
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      getOptionKey={(x) => x.place_id}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField {...params} label="Address" fullWidth />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings ?? []

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: { offset: number, length: number }) => [match.offset, match.offset + match.length])
        )

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box
                    key={index}
                    component="span"
                    sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                  >
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        )
      }}
    />
  )
}

export default GoogleMaps
